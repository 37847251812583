export const userData =
 {
    employeeId: 'NV001',
    name: 'Marc Backes',
    gender: 'Nam',
    birthDate: '01/01/1990',
    phoneNumber: '0123456789',
    address: '123 Example Street, City, Country',
    position: 'ADMIN',
    avatar: "https://i.pinimg.com/564x/27/cb/70/27cb70c5b0989fb48d1d06bc32143239.jpg",


  }
  export default userData;