import React from 'react'
import CustomerList from './CustomerList'
export default function Customer() {
  return (
    <div className='w-full h-full relative overflow-hidden'>
      
      <CustomerList/>
     

    </div>
  )
}
