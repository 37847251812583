export const bookData =
[
 {
    OrderId: 'HD001',
    staffCreateName: 'Marc Backes',
    status: 4,
    dayCreate: '01/01/1990',
    phoneNumber: '0123456789',
    address: '123 Example Street, City, Country',
    providerName: '10 diem do an',
    discount: 10000,
    CusId: 'KH004',
    CusName: 'BAnh',
    Total: '350.000',
    Status: 0,
    Day: '14/05/2024',
    bookDetail: [
        {
            avatar: "https://i.pinimg.com/564x/26/4a/bf/264abfb5c7aea17ca469f360e4860edf.jpg",
            bookName: 'Litte Women',
            price: 100000,
            quantity: 4
        },
        {
            avatar: "https://i.pinimg.com/564x/7f/de/0b/7fde0b91e49ef399584d665a09cf43b5.jpg",
            bookName: 'Litte Women',
            price: 100000,
            quantity: 3
        },
        {
            avatar: "https://i.pinimg.com/564x/12/a8/cf/12a8cf99dbb5a8c04dbaa2a07cf6eb67.jpg",
            bookName: 'Litte Women',
            price: 100000,
            quantity: 2
        },
        {
            avatar: "https://i.pinimg.com/564x/46/0b/82/460b822eb760e8d61725410aa9f5ac29.jpg",
            bookName: 'Litte Women',
            price: 100000,
            quantity: 4
        },
        {
            avatar: "https://i.pinimg.com/736x/6b/5d/71/6b5d718b869c166fceaf1da65327b2bc.jpg",
            bookName: 'Litte Women',
            price: 100000,
            quantity: 3
        },
        {
            avatar: "https://i.pinimg.com/564x/e2/31/30/e23130e249e1dbb8429ba3f1054e1348.jpg",
            bookName: 'Litte Women',
            price: 100000,
            quantity: 2
        },
    ]
   

  },
  {
    OrderId: 'HD002',
    staffCreateName: 'BAnh',
    status: 4,
    dayCreate: '01/01/1990',
    phoneNumber: '0123456789',
    address: '123 Example Street, City, Country',
    providerName: '10 diem do an',
    discount: 10000,
    CusId: 'KH004',
    CusName: 'BAnh',
    Total: '350.000',
    Status: 0,
    Day: '14/05/2024',
    bookDetail: [
        {
            avatar: "https://i.pinimg.com/564x/26/4a/bf/264abfb5c7aea17ca469f360e4860edf.jpg",
            bookName: 'Litte Women',
            price: 100000,
            quantity: 4
        },
        {
            avatar: "https://i.pinimg.com/564x/7f/de/0b/7fde0b91e49ef399584d665a09cf43b5.jpg",
            bookName: 'Litte Women',
            price: 100000,
            quantity: 3
        },
        {
            avatar: "https://i.pinimg.com/564x/12/a8/cf/12a8cf99dbb5a8c04dbaa2a07cf6eb67.jpg",
            bookName: 'Litte Women',
            price: 100000,
            quantity: 2
        },
        {
            avatar: "https://i.pinimg.com/564x/46/0b/82/460b822eb760e8d61725410aa9f5ac29.jpg",
            bookName: 'Litte Women',
            price: 100000,
            quantity: 4
        },
        {
            avatar: "https://i.pinimg.com/736x/6b/5d/71/6b5d718b869c166fceaf1da65327b2bc.jpg",
            bookName: 'Litte Women',
            price: 100000,
            quantity: 3
        },
        {
            avatar: "https://i.pinimg.com/564x/e2/31/30/e23130e249e1dbb8429ba3f1054e1348.jpg",
            bookName: 'Litte Women',
            price: 100000,
            quantity: 2
        },
    ]
   

  },
  {
    OrderId: 'HD003',
    staffCreateName: 'Marc Backes',
    status: 4,
    dayCreate: '01/01/1990',
    phoneNumber: '0123456789',
    address: '123 Example Street, City, Country',
    providerName: '10 diem do an',
    discount: 10000,
    CusId: 'KH004',
    CusName: 'BAnh',
    Total: '350.000',
    Status: 0,
    Day: '14/05/2024',
    bookDetail: [
        {
            avatar: "https://i.pinimg.com/564x/26/4a/bf/264abfb5c7aea17ca469f360e4860edf.jpg",
            bookName: 'Litte Women',
            price: 100000,
            quantity: 4
        },
        {
            avatar: "https://i.pinimg.com/564x/7f/de/0b/7fde0b91e49ef399584d665a09cf43b5.jpg",
            bookName: 'Litte Women',
            price: 100000,
            quantity: 3
        },
        {
            avatar: "https://i.pinimg.com/564x/12/a8/cf/12a8cf99dbb5a8c04dbaa2a07cf6eb67.jpg",
            bookName: 'Litte Women',
            price: 100000,
            quantity: 2
        },
        {
            avatar: "https://i.pinimg.com/564x/46/0b/82/460b822eb760e8d61725410aa9f5ac29.jpg",
            bookName: 'Litte Women',
            price: 100000,
            quantity: 4
        },
        {
            avatar: "https://i.pinimg.com/736x/6b/5d/71/6b5d718b869c166fceaf1da65327b2bc.jpg",
            bookName: 'Litte Women',
            price: 100000,
            quantity: 3
        },
        {
            avatar: "https://i.pinimg.com/564x/e2/31/30/e23130e249e1dbb8429ba3f1054e1348.jpg",
            bookName: 'Litte Women',
            price: 100000,
            quantity: 2
        },
    ]
   

  },
  {
    OrderId: 'HD004',
    staffCreateName: 'BAnh',
    status: 4,
    dayCreate: '01/01/1990',
    phoneNumber: '0123456789',
    address: '123 Example Street, City, Country',
    providerName: '10 diem do an',
    discount: 10000,
    CusId: 'KH004',
    CusName: 'BAnh',
    Total: '350.000',
    Status: 0,
    Day: '14/05/2024',
    bookDetail: [
        {
            avatar: "https://i.pinimg.com/564x/26/4a/bf/264abfb5c7aea17ca469f360e4860edf.jpg",
            bookName: 'Litte Women',
            price: 100000,
            quantity: 4
        },
        {
            avatar: "https://i.pinimg.com/564x/7f/de/0b/7fde0b91e49ef399584d665a09cf43b5.jpg",
            bookName: 'Litte Women',
            price: 100000,
            quantity: 3
        },
        {
            avatar: "https://i.pinimg.com/564x/12/a8/cf/12a8cf99dbb5a8c04dbaa2a07cf6eb67.jpg",
            bookName: 'Litte Women',
            price: 100000,
            quantity: 2
        },
        {
            avatar: "https://i.pinimg.com/564x/46/0b/82/460b822eb760e8d61725410aa9f5ac29.jpg",
            bookName: 'Litte Women',
            price: 100000,
            quantity: 4
        },
        {
            avatar: "https://i.pinimg.com/736x/6b/5d/71/6b5d718b869c166fceaf1da65327b2bc.jpg",
            bookName: 'Litte Women',
            price: 100000,
            quantity: 3
        },
        {
            avatar: "https://i.pinimg.com/564x/e2/31/30/e23130e249e1dbb8429ba3f1054e1348.jpg",
            bookName: 'Litte Women',
            price: 100000,
            quantity: 2
        },
    ]
   

  },
  {
    OrderId: 'HD005',
    staffCreateName: 'Marc Backes',
    status: 4,
    dayCreate: '01/01/1990',
    phoneNumber: '0123456789',
    address: '123 Example Street, City, Country',
    providerName: '10 diem do an',
    discount: 10000,
    CusId: 'KH004',
    CusName: 'BAnh',
    Total: '350.000',
    Status: 0,
    Day: '14/05/2024',
    bookDetail: [
        {
            avatar: "https://i.pinimg.com/564x/26/4a/bf/264abfb5c7aea17ca469f360e4860edf.jpg",
            bookName: 'Litte Women',
            price: 100000,
            quantity: 4
        },
        {
            avatar: "https://i.pinimg.com/564x/7f/de/0b/7fde0b91e49ef399584d665a09cf43b5.jpg",
            bookName: 'Litte Women',
            price: 100000,
            quantity: 3
        },
        {
            avatar: "https://i.pinimg.com/564x/12/a8/cf/12a8cf99dbb5a8c04dbaa2a07cf6eb67.jpg",
            bookName: 'Litte Women',
            price: 100000,
            quantity: 2
        },
        {
            avatar: "https://i.pinimg.com/564x/46/0b/82/460b822eb760e8d61725410aa9f5ac29.jpg",
            bookName: 'Litte Women',
            price: 100000,
            quantity: 4
        },
        {
            avatar: "https://i.pinimg.com/736x/6b/5d/71/6b5d718b869c166fceaf1da65327b2bc.jpg",
            bookName: 'Litte Women',
            price: 100000,
            quantity: 3
        },
        {
            avatar: "https://i.pinimg.com/564x/e2/31/30/e23130e249e1dbb8429ba3f1054e1348.jpg",
            bookName: 'Litte Women',
            price: 100000,
            quantity: 2
        },
    ]
   

  },
  {
    OrderId: 'HD006',
    staffCreateName: 'BAnh',
    status: 4,
    dayCreate: '01/01/1990',
    phoneNumber: '0123456789',
    address: '123 Example Street, City, Country',
    providerName: '10 diem do an',
    discount: 10000,
    CusId: 'KH004',
    CusName: 'BAnh',
    Total: '350.000',
    Status: 0,
    Day: '14/05/2024',
    bookDetail: [
        {
            avatar: "https://i.pinimg.com/564x/26/4a/bf/264abfb5c7aea17ca469f360e4860edf.jpg",
            bookName: 'Litte Women',
            price: 100000,
            quantity: 4
        },
        {
            avatar: "https://i.pinimg.com/564x/7f/de/0b/7fde0b91e49ef399584d665a09cf43b5.jpg",
            bookName: 'Litte Women',
            price: 100000,
            quantity: 3
        },
        {
            avatar: "https://i.pinimg.com/564x/12/a8/cf/12a8cf99dbb5a8c04dbaa2a07cf6eb67.jpg",
            bookName: 'Litte Women',
            price: 100000,
            quantity: 2
        },
        {
            avatar: "https://i.pinimg.com/564x/46/0b/82/460b822eb760e8d61725410aa9f5ac29.jpg",
            bookName: 'Litte Women',
            price: 100000,
            quantity: 4
        },
        {
            avatar: "https://i.pinimg.com/736x/6b/5d/71/6b5d718b869c166fceaf1da65327b2bc.jpg",
            bookName: 'Litte Women',
            price: 100000,
            quantity: 3
        },
        {
            avatar: "https://i.pinimg.com/564x/e2/31/30/e23130e249e1dbb8429ba3f1054e1348.jpg",
            bookName: 'Litte Women',
            price: 100000,
            quantity: 2
        },
    ]
   

  },
  {
    OrderId: 'HD007',
    staffCreateName: 'Marc Backes',
    status: 4,
    dayCreate: '01/01/1990',
    phoneNumber: '0123456789',
    address: '123 Example Street, City, Country',
    providerName: '10 diem do an',
    discount: 10000,
    CusId: 'KH004',
    CusName: 'BAnh',
    Total: '350.000',
    Status: 0,
    Day: '14/05/2024',
    bookDetail: [
        {
            avatar: "https://i.pinimg.com/564x/26/4a/bf/264abfb5c7aea17ca469f360e4860edf.jpg",
            bookName: 'Litte Women',
            price: 100000,
            quantity: 4
        },
        {
            avatar: "https://i.pinimg.com/564x/7f/de/0b/7fde0b91e49ef399584d665a09cf43b5.jpg",
            bookName: 'Litte Women',
            price: 100000,
            quantity: 3
        },
        {
            avatar: "https://i.pinimg.com/564x/12/a8/cf/12a8cf99dbb5a8c04dbaa2a07cf6eb67.jpg",
            bookName: 'Litte Women',
            price: 100000,
            quantity: 2
        },
        {
            avatar: "https://i.pinimg.com/564x/46/0b/82/460b822eb760e8d61725410aa9f5ac29.jpg",
            bookName: 'Litte Women',
            price: 100000,
            quantity: 4
        },
        {
            avatar: "https://i.pinimg.com/736x/6b/5d/71/6b5d718b869c166fceaf1da65327b2bc.jpg",
            bookName: 'Litte Women',
            price: 100000,
            quantity: 3
        },
        {
            avatar: "https://i.pinimg.com/564x/e2/31/30/e23130e249e1dbb8429ba3f1054e1348.jpg",
            bookName: 'Litte Women',
            price: 100000,
            quantity: 2
        },
    ]
   

  },
  {
    OrderId: 'HD008',
    staffCreateName: 'BAnh',
    status: 4,
    dayCreate: '01/01/1990',
    phoneNumber: '0123456789',
    address: '123 Example Street, City, Country',
    providerName: '10 diem do an',
    discount: 10000,
    CusId: 'KH004',
    CusName: 'BAnh',
    Total: '350.000',
    Status: 0,
    Day: '14/05/2024',
    bookDetail: [
        {
            avatar: "https://i.pinimg.com/564x/26/4a/bf/264abfb5c7aea17ca469f360e4860edf.jpg",
            bookName: 'Litte Women',
            price: 100000,
            quantity: 4
        },
        {
            avatar: "https://i.pinimg.com/564x/7f/de/0b/7fde0b91e49ef399584d665a09cf43b5.jpg",
            bookName: 'Litte Women',
            price: 100000,
            quantity: 3
        },
        {
            avatar: "https://i.pinimg.com/564x/12/a8/cf/12a8cf99dbb5a8c04dbaa2a07cf6eb67.jpg",
            bookName: 'Litte Women',
            price: 100000,
            quantity: 2
        },
        {
            avatar: "https://i.pinimg.com/564x/46/0b/82/460b822eb760e8d61725410aa9f5ac29.jpg",
            bookName: 'Litte Women',
            price: 100000,
            quantity: 4
        },
        {
            avatar: "https://i.pinimg.com/736x/6b/5d/71/6b5d718b869c166fceaf1da65327b2bc.jpg",
            bookName: 'Litte Women',
            price: 100000,
            quantity: 3
        },
        {
            avatar: "https://i.pinimg.com/564x/e2/31/30/e23130e249e1dbb8429ba3f1054e1348.jpg",
            bookName: 'Litte Women',
            price: 100000,
            quantity: 2
        },
    ]
   

  },
  {
    OrderId: 'HD009',
    staffCreateName: 'Marc Backes',
    status: 4,
    dayCreate: '01/01/1990',
    phoneNumber: '0123456789',
    address: '123 Example Street, City, Country',
    providerName: '10 diem do an',
    discount: 10000,
    CusId: 'KH004',
    CusName: 'BAnh',
    Total: '350.000',
    Status: 0,
    Day: '14/05/2024',
    bookDetail: [
        {
            avatar: "https://i.pinimg.com/564x/26/4a/bf/264abfb5c7aea17ca469f360e4860edf.jpg",
            bookName: 'Litte Women',
            price: 100000,
            quantity: 4
        },
        {
            avatar: "https://i.pinimg.com/564x/7f/de/0b/7fde0b91e49ef399584d665a09cf43b5.jpg",
            bookName: 'Litte Women',
            price: 100000,
            quantity: 3
        },
        {
            avatar: "https://i.pinimg.com/564x/12/a8/cf/12a8cf99dbb5a8c04dbaa2a07cf6eb67.jpg",
            bookName: 'Litte Women',
            price: 100000,
            quantity: 2
        },
        {
            avatar: "https://i.pinimg.com/564x/46/0b/82/460b822eb760e8d61725410aa9f5ac29.jpg",
            bookName: 'Litte Women',
            price: 100000,
            quantity: 4
        },
        {
            avatar: "https://i.pinimg.com/736x/6b/5d/71/6b5d718b869c166fceaf1da65327b2bc.jpg",
            bookName: 'Litte Women',
            price: 100000,
            quantity: 3
        },
        {
            avatar: "https://i.pinimg.com/564x/e2/31/30/e23130e249e1dbb8429ba3f1054e1348.jpg",
            bookName: 'Litte Women',
            price: 100000,
            quantity: 2
        },
    ]
   

  },
  {
    OrderId: 'HD0010',
    staffCreateName: 'BAnh',
    status: 4,
    dayCreate: '01/01/1990',
    phoneNumber: '0123456789',
    address: '123 Example Street, City, Country',
    providerName: '10 diem do an',
    discount: 10000,
    CusId: 'KH004',
    CusName: 'BAnh',
    Total: '350.000',
    Status: 0,
    Day: '14/05/2024',
    bookDetail: [
        {
            avatar: "https://i.pinimg.com/564x/26/4a/bf/264abfb5c7aea17ca469f360e4860edf.jpg",
            bookName: 'Litte Women',
            price: 100000,
            quantity: 4
        },
        {
            avatar: "https://i.pinimg.com/564x/7f/de/0b/7fde0b91e49ef399584d665a09cf43b5.jpg",
            bookName: 'Litte Women',
            price: 100000,
            quantity: 3
        },
        {
            avatar: "https://i.pinimg.com/564x/12/a8/cf/12a8cf99dbb5a8c04dbaa2a07cf6eb67.jpg",
            bookName: 'Litte Women',
            price: 100000,
            quantity: 2
        },
        {
            avatar: "https://i.pinimg.com/564x/46/0b/82/460b822eb760e8d61725410aa9f5ac29.jpg",
            bookName: 'Litte Women',
            price: 100000,
            quantity: 4
        },
        {
            avatar: "https://i.pinimg.com/736x/6b/5d/71/6b5d718b869c166fceaf1da65327b2bc.jpg",
            bookName: 'Litte Women',
            price: 100000,
            quantity: 3
        },
        {
            avatar: "https://i.pinimg.com/564x/e2/31/30/e23130e249e1dbb8429ba3f1054e1348.jpg",
            bookName: 'Litte Women',
            price: 100000,
            quantity: 2
        },
    ]
   

  },
  {
    OrderId: 'HD0011',
    staffCreateName: 'Marc Backes',
    status: 4,
    dayCreate: '01/01/1990',
    phoneNumber: '0123456789',
    address: '123 Example Street, City, Country',
    providerName: '10 diem do an',
    discount: 10000,
    CusId: 'KH004',
    CusName: 'BAnh',
    Total: '350.000',
    Status: 0,
    Day: '14/05/2024',
    bookDetail: [
        {
            avatar: "https://i.pinimg.com/564x/26/4a/bf/264abfb5c7aea17ca469f360e4860edf.jpg",
            bookName: 'Litte Women',
            price: 100000,
            quantity: 4
        },
        {
            avatar: "https://i.pinimg.com/564x/7f/de/0b/7fde0b91e49ef399584d665a09cf43b5.jpg",
            bookName: 'Litte Women',
            price: 100000,
            quantity: 3
        },
        {
            avatar: "https://i.pinimg.com/564x/12/a8/cf/12a8cf99dbb5a8c04dbaa2a07cf6eb67.jpg",
            bookName: 'Litte Women',
            price: 100000,
            quantity: 2
        },
        {
            avatar: "https://i.pinimg.com/564x/46/0b/82/460b822eb760e8d61725410aa9f5ac29.jpg",
            bookName: 'Litte Women',
            price: 100000,
            quantity: 4
        },
        {
            avatar: "https://i.pinimg.com/736x/6b/5d/71/6b5d718b869c166fceaf1da65327b2bc.jpg",
            bookName: 'Litte Women',
            price: 100000,
            quantity: 3
        },
        {
            avatar: "https://i.pinimg.com/564x/e2/31/30/e23130e249e1dbb8429ba3f1054e1348.jpg",
            bookName: 'Litte Women',
            price: 100000,
            quantity: 2
        },
    ]
   

  },
  {
    OrderId: 'HD0012',
    staffCreateName: 'BAnh',
    status: 4,
    dayCreate: '01/01/1990',
    phoneNumber: '0123456789',
    address: '123 Example Street, City, Country',
    providerName: '10 diem do an',
    discount: 10000,
    CusId: 'KH004',
    CusName: 'BAnh',
    Total: '350.000',
    Status: 0,
    Day: '14/05/2024',
    bookDetail: [
        {
            avatar: "https://i.pinimg.com/564x/26/4a/bf/264abfb5c7aea17ca469f360e4860edf.jpg",
            bookName: 'Litte Women',
            price: 100000,
            quantity: 4
        },
        {
            avatar: "https://i.pinimg.com/564x/7f/de/0b/7fde0b91e49ef399584d665a09cf43b5.jpg",
            bookName: 'Litte Women',
            price: 100000,
            quantity: 3
        },
        {
            avatar: "https://i.pinimg.com/564x/12/a8/cf/12a8cf99dbb5a8c04dbaa2a07cf6eb67.jpg",
            bookName: 'Litte Women',
            price: 100000,
            quantity: 2
        },
        {
            avatar: "https://i.pinimg.com/564x/46/0b/82/460b822eb760e8d61725410aa9f5ac29.jpg",
            bookName: 'Litte Women',
            price: 100000,
            quantity: 4
        },
        {
            avatar: "https://i.pinimg.com/736x/6b/5d/71/6b5d718b869c166fceaf1da65327b2bc.jpg",
            bookName: 'Litte Women',
            price: 100000,
            quantity: 3
        },
        {
            avatar: "https://i.pinimg.com/564x/e2/31/30/e23130e249e1dbb8429ba3f1054e1348.jpg",
            bookName: 'Litte Women',
            price: 100000,
            quantity: 2
        },
    ]
   

  },
  {
    OrderId: 'HD0013',
    staffCreateName: 'Marc Backes',
    status: 4,
    dayCreate: '01/01/1990',
    phoneNumber: '0123456789',
    address: '123 Example Street, City, Country',
    providerName: '10 diem do an',
    discount: 10000,
    CusId: 'KH004',
    CusName: 'BAnh',
    Total: '350.000',
    Status: 0,
    Day: '14/05/2024',
   
    bookDetail: [
        {
            avatar: "https://i.pinimg.com/564x/26/4a/bf/264abfb5c7aea17ca469f360e4860edf.jpg",
            bookName: 'Litte Women',
            price: 100000,
            quantity: 4
        },
        {
            avatar: "https://i.pinimg.com/564x/7f/de/0b/7fde0b91e49ef399584d665a09cf43b5.jpg",
            bookName: 'Litte Women',
            price: 100000,
            quantity: 3
        },
        {
            avatar: "https://i.pinimg.com/564x/12/a8/cf/12a8cf99dbb5a8c04dbaa2a07cf6eb67.jpg",
            bookName: 'Litte Women',
            price: 100000,
            quantity: 2
        },
        {
            avatar: "https://i.pinimg.com/564x/46/0b/82/460b822eb760e8d61725410aa9f5ac29.jpg",
            bookName: 'Litte Women',
            price: 100000,
            quantity: 4
        },
        {
            avatar: "https://i.pinimg.com/736x/6b/5d/71/6b5d718b869c166fceaf1da65327b2bc.jpg",
            bookName: 'Litte Women',
            price: 100000,
            quantity: 3
        },
        {
            avatar: "https://i.pinimg.com/564x/e2/31/30/e23130e249e1dbb8429ba3f1054e1348.jpg",
            bookName: 'Litte Women',
            price: 100000,
            quantity: 2
        },
    ]
   

  },
  
]
  export default bookData;