import Maincontent from './Maincontent';

const Dashboard = () => {

  return (
    <div className='w-full h-full'>
      <Maincontent />
    </div>
  );
};
export default Dashboard;